import React from 'react';
import { SquaresInfo } from '../Context';
import { createIFrameURL } from '../helpers/misc';

export interface FullPageQlikIFrameProps {
  sheetId: string;
}

export default function FullPageQlikIFrame(props: FullPageQlikIFrameProps) {
  const squaresInfo = React.useContext(SquaresInfo);

  return <div style={{
    flex: 1
  }}>
    <iframe src={createIFrameURL(squaresInfo.appId, props.sheetId)} style={{
      width: "100%",
      height: "100%",
      border: "none"
    }}></iframe>
  </div>
}