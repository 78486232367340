import { Button } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React from 'react';
import { SquaresInfo } from '../Context';
// import './Data.scss'
import { createIFrameURL } from '../helpers/misc';
import { ImportDialog } from '../components/ImportDialog';

function TabPanel({ value, index, children }) {
  if (value != index) return <></>;
  return <>{children}</>;
}

export default function DataPage() {
  const sheetId = "61b73242-d91a-4217-9ca2-aa8bbe0578b0";
  const [tabIndex, setTabIndex] = React.useState(0);
  const [importStage, setImportStage] = React.useState(0);
  const [importProgress, setImportProgress] = React.useState(0);
  const squaresInfo = React.useContext(SquaresInfo);
  function handleTabChange(event, value) {
    setTabIndex(value);
  }

  function startImport() {
    setImportStage(1)
  }

  function stopImport() {
    setImportStage(0)
  }

  function openImportDialog() {

  }

  return <div style={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
  }}>
    {
      importStage == 1 &&
      <ImportDialog
        importProgress={importProgress}
        stopImport={() => stopImport()}
        importSuccess={() => {
          console.log("Success")
          setImportStage(2)
          setTabIndex(1)
        }}
        isOpen={importStage == 1} />
    }

    <Tabs value={tabIndex} onChange={handleTabChange}>
      <Tab label="Import" />
      <Tab label="Export" /* disabled={importStage == 0} */ />
    </Tabs>

    <TabPanel value={tabIndex} index={0}>
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "50%",
      }}>
        <Button variant="contained" onClick={startImport} >Import data</Button>
      </div>
    </TabPanel>
    <TabPanel value={tabIndex} index={1}>
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}>
        <iframe src={createIFrameURL(squaresInfo.appId, sheetId)} style={{
          width: "100%",
          height: "100%",
          border: "none"
        }}></iframe>
      </div>
    </TabPanel>
  </div>
}
