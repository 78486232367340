import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../pages/Login/square.png';
import LogOut from './LogOut.jsx';
import './SideBar.scss';

interface RouteInfoLink {
  name: string;
  route: string; // The route for navigation.
  img: string;
  type?: 'link'; // The type of the route object.
}

interface RouteInfoCategory {
  name: string;
  img: string;
  type: 'category'; // The type of the route object.
}

export type RouteInfo = RouteInfoLink | RouteInfoCategory;

export default function SideBar(props: { pages: RouteInfo[] }) {
  const route = useLocation();
  return (
    <div className="sidebar-component">
      <img
        className="sidebar-logo"
        src={Logo}
        alt="Symboticware"
        style={{ filter: 'invert(1)' }}
      />
      <h1
        style={{
          fontWeight: '200',
          fontFamily: 'Russo One',
          marginTop: '0.2rem',
        }}
      >
        Squares
      </h1>
      <div className="sidebar-section">
        {
          // Render links for the sidebar
          props.pages.map((page) => {

            // A category
            if (page.type == 'category') {
              return (
                <div className="sidebar_category">
                  <img className="sidebar-img" src={`${page.img}`} />
                  <h1>{page.name}</h1>
                </div>
              );
            }

            // A link element
            return (
              <Link
                className={`sidebar_p1 ${
                  route.pathname == page.route ? 'selected' : ''
                }`}
                to={page.route}
                key={`${page.name}:${page.route}`}
              >
                <img className="sidebar-img" src={`${page.img}`} />
                {page.name}
              </Link>
            );
          })
        }
        <LogOut />
      </div>
    </div>
  );
}
