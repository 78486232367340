import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { wait } from '../helpers/misc';
import {
  getAssetTrees,
  getFileUploadURLs,
  signalFilesUploaded,
  uploadFile,
} from '../helpers/squaresAPI';
import { BasicSelect } from './Dropdown';
import { InputFileButton } from './ImportFileButton';

export function ImportDialog({
  importSuccess,
  importProgress,
  stopImport,
  isOpen,
}) {
  const [dataFile, setDataFile] = useState(null);
  const [assetTreeName, setAssetTreeName] = useState(null);
  const [uploadStage, setUploadStage] = useState(0);
  const [zipFileName, setZipFileName] = useState(null);
  const [importProgress2, setImportProgress] = useState(0);
  const [availableAssetTrees, setAvailableAssetTrees] = useState([]);

  const [progressMessage, setProgressMessage] = useState(null);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isAlive = true;
    async function setupAssetTrees() {
      // Fetch the asset trees from the Squares API
      const assetTrees = await getAssetTrees();

      // The page is in focus
      if (isAlive) {
        // Map the asset tree list to display it as a dropdown
        setAvailableAssetTrees(
          assetTrees.map((assetTree) => ({
            text: assetTree.name,
            val: assetTree.name,
          }))
        );
      }
    }

    setupAssetTrees();
    return () => {
      // The page is out of focus
      isAlive = false;
    };
  }, []);

  function handleDataFile(files) {
    window.sam = files[0];
    setDataFile(files[0]);
  }

  function handleFileName(event) {
    setZipFileName(event.target.value);
  }

  const emptyFormInputs = !dataFile || !assetTreeName || !zipFileName;

  async function uploadData() {
    if (emptyFormInputs) return;

    setUploadStage(1);
    try {
      const { assetTree, data, clientId } = await getFileUploadURLs();

      setProgressMessage('Uploading asset tree');
      setProgressMessage('Uploading data tree');
      await uploadFile(data, await fileToBinary(dataFile));

      setProgressMessage('Preparing files for parsing');
      await signalFilesUploaded(clientId, zipFileName, assetTreeName);

      await fileProcessingAnimation(setProgressMessage, setImportProgress);
      importSuccess();
    } catch (exception) {
      console.log('error caught:', exception.message);
      setShowError(true);
      setError(exception);
      setUploadStage(0);
    }
  }

  async function fileToBinary(file) {
    return new Blob((await file.text()).split(''));
    return new Blob(new Uint8Array(await file.arrayBuffer()));
  }

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Upload Files</DialogTitle>
      <DialogContent>
        {/* 1 Select files */}
        <div style={{ marginBottom: 12 }}>
          <p>Please import your dataset as a CSV.</p>
          <p>
            File name: <input type="text" onChange={handleFileName}></input>
          </p>

          <div style={{ display: 'flex' }}>
            <InputFileButton
              disabled={uploadStage == 1}
              handleFileUpload={handleDataFile}
              style={{ marginRight: 12 }}
            >
              Upload Data (CSV)
            </InputFileButton>

            <BasicSelect
              disabled={uploadStage == 1}
              name={'Asset Tree'}
              optionsList={availableAssetTrees}
              onChange={(treeName) => {
                setAssetTreeName(treeName);
              }}
            />
          </div>
        </div>

        {
          // 2 Upload files
          uploadStage == 1 && (
            <Alert severity="info">
              <AlertTitle>Dataset import in progress</AlertTitle>

              <p>{progressMessage}</p>
              {importProgress2 != 0 && (
                <LinearProgress variant="determinate" value={importProgress2} />
              )}
              {/* You will receive an email when completed */}
            </Alert>
          )
        }

        {showError && error && (
          <Alert severity="error">
            <AlertTitle>Upload Error</AlertTitle>
            <p>Something went wrong when uploading your file.</p>
            <p>{error.message}</p>
            <p>{error.stack}</p>
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={uploadData}
          disabled={emptyFormInputs || (!emptyFormInputs && uploadStage == 1)}
        >
          Upload
        </Button>
        <Button
          onClick={() => {
            // Reset the stage
            setUploadStage(0);
            stopImport();
          }}
          disabled={uploadStage == 1}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

async function fileProcessingAnimation(setProgressMessage, setImportProgress) {
  setProgressMessage('Decompressing models');
  setImportProgress(1);
  await wait(10 * 50);

  setProgressMessage('Resolving dependency tree');
  setImportProgress(20);
  await wait(8 * 50);

  setProgressMessage('Importing sentence transformers');
  setImportProgress(40);
  await wait(10 * 50);

  setProgressMessage('Running NLP models');
  setImportProgress(50);
  await wait(5 * 50);

  setProgressMessage('Categorizing work orders');
  setImportProgress(60);
  await wait(7 * 50);

  setProgressMessage('Exporting data');
  setImportProgress(99);
  await wait(10 * 50);

  setProgressMessage(
    'Data upload and processing is complete. Upload to visualisation engine has begun. ETA: 3min. It is safe to use the rest of the app while the data loads'
  );
  setImportProgress(100);
  await wait(5 * 50);
}
