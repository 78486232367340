import { Button, LinearProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useState } from 'react';

export function InputFileButton({ children, handleFileUpload, ...other }) {
  const [fileName, setFileName] = useState(null)
  function fileUpload(files, event) {
    setFileName(files[0].name)
    handleFileUpload(files, event)
  }
  return <div>
    <Button variant="outlined" component="label" {...other}>
      {children}
      <input type="file" hidden onChange={(event) => fileUpload(event.target.files, event)} />
    </Button>
    {
      fileName != null &&
      <div>
        <small>{fileName}</small>
      </div>
    }
  </div>
}