import { qlikTenantDomain } from "../../../lib/qlikLib"

export async function wait(delay) {
  return new Promise((res, rej) => {
    setTimeout(() => {
      res()
    }, delay)
  })
}

export function createIFrameURL(appId, sheetId) {
  return `https://${qlikTenantDomain}/single/?appid=${appId}&sheet=${sheetId}&theme=horizon&opt=ctxmenu,currsel`;
}

export const squares_qlikAppID = "ad31acf8-84fc-4e81-9624-5c39856868af"
