import { API, Auth } from "aws-amplify";

export function clientInit() {
  return API.get("squares", "/client/init", {});
}

export function getFileUploadURLs() {
  return API.get("squares", "/client/dataset/upload", {});
}

export function signalFilesUploaded(clientId: string, fileName: string, assetTree: string) {
  return API.get("squares", "/client/dataset/upload/ready", {
    queryStringParameters: {clientId, fileName, assetTree}
  }).catch(error => {
    error.message = `[squaresAPI] ${error.message} - ${error.response.data}`;
    throw error;
  })
}

export async function uploadFile(s3UploadURL, file) {
  return fetch(s3UploadURL, {
    method: 'PUT',
    body: file
  });
}

export async function reloadQlikApp(): Promise<void> {
  return API.get("squares", "/qlik/reload", {});
}

export async function getAssetTrees() {
  return API.get("squares", "/assetTree", {});
}