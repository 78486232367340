import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import dashboard from '../../assets/squares/Dashboard.png';
import data from '../../assets/squares/Data.png';
import engine from '../../assets/squares/engine.png';
import fleet from '../../assets/squares/Fleet Overview.png';
import paymentVisualisation from '../../assets/squares/pay-per-visualization.png';
import telescope from '../../assets/squares/telescope.png';
import heartbeat from '../../assets/squares/heartbeat.png';
import units from '../../assets/squares/Unit Overview.png';
import SideBar, { RouteInfo } from '../../components/SideBar';
import { getQixAPI } from '../../lib/qlikLib';
import FullPageQlikIFrame from './components/FullPageQlikIFrame';
import { SquaresInfo } from './Context';
import { clientInit, reloadQlikApp } from './helpers/squaresAPI';
import DataPage from './pages/DataPage';
import fortuneTeller from "../../assets/squares/fortune-teller.png";
import file from "../../assets/squares/file.png";

const pages: RouteInfo[] = [
  {
    name: 'Data',
    route: '/data',
    img: data,
  },
  {
    name: 'Dashboard',
    route: '/dashboard',
    img: dashboard,
  },
  {
    type: 'category',
    name: 'Cost control',
    img: paymentVisualisation,
    // TODO: Attribution required
    // https://www.flaticon.com/free-icon/pay-per-visualization_7322330?related_id=7322330&origin=search%E2%80%8B#
  },
  {
    name: 'Fleet',
    route: '/fleet',
    img: fleet,
  },
  // {
  //   name: 'Units',
  //   route: '/units',
  //   img: units,
  // },
  {
    name: 'Components',
    route: '/component',
    img: engine,
  },
  {
    type: 'category',
    name: 'Survival Analysis',
    // https://www.flaticon.com/free-icon/telescope_8619178?term=spyglass&page=2&position=44&page=2&position=44&related_id=8619178&origin=search#
    img: heartbeat,
  },
  {
    name: 'Overview',
    route: '/survival/overview',
    img: file,
  },
  {
    name: 'Prognosis',
    route: '/survival/prognosis',
    img: fortuneTeller,
  },
];

export default function Routes() {
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState<Error | null>(null);

  if (error != null) {
    throw error;
  }
  useEffect(() => {
    async function asyncSetup() {
      const user = await clientInit();
      setUserInfo(user);
      getQixAPI(user.appId);
      await reloadQlikApp().catch((error) => {
        error.message = `reloadQlikApp: ${error.message}`;
        // TODO Throw error or message that the reload failed
      });
    }
    asyncSetup().catch((error) => {
      if (error.isAxiosError) {
        console.log(error.response);
        setError(new Error(error.response.data));
      } else {
        throw error;
      }
    });
  }, []);

  if (userInfo == null) return <></>;

  return (
    <>
      <SideBar pages={pages} />
      <SquaresInfo.Provider value={userInfo}>
        <Switch>
          <Redirect exact path="/" to="/data" />
          <Route path="/data" component={DataPage} />
          <Route
            path="/dashboard"
            component={() => (
              <FullPageQlikIFrame sheetId="5a3edefe-2baa-48a8-90c3-c7eb3c125837" />
            )}
          />
          <Route
            path="/units"
            component={() => (
              <FullPageQlikIFrame sheetId="a020ff1b-a529-4de2-bba4-0682624243c7" />
            )}
          />
          <Route
            path="/fleet"
            component={() => (
              <FullPageQlikIFrame sheetId="8f249eb6-97eb-403c-b311-e6847ec9db75" />
            )}
          />
          <Route
            path="/component"
            component={() => (
              <FullPageQlikIFrame sheetId="edb95ea1-0920-4ce1-8e6a-99fa8088bea6" />
            )}
          />
          <Route
            path="/survival/overview"
            component={() => (
              <FullPageQlikIFrame sheetId="181fc565-ff1f-4249-94d1-077938deb34a" />
            )}
          />
          <Route
            path="/survival/prognosis"
            component={() => (
              <FullPageQlikIFrame sheetId="01f51342-9853-47c0-adb5-7e2445993cc4" />
            )}
          />
        </Switch>
      </SquaresInfo.Provider>
    </>
  );
}
